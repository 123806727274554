.item-card {
  font-family: "Open Sans Hebrew";
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  padding: 16px;
  padding-bottom: 5px;
  border: solid 1px #f2f2f2;
  transition: all 0.5s;
  box-shadow: none;
  cursor: pointer;
  height: 315px;

  .kosher-tag {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #047d06;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4x;
    font-size: 12px;
    font-weight: bold;
    border-top-right-radius: 0px; 
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 10px;
  }

  &:hover {
    -moz-box-shadow: inset 0 0 10px #808080;
    -webkit-box-shadow: inset 0 0 10px #808080;
    box-shadow: inset 0 0 10px #808080;

    .card-img {
      height: 165px;
    }

    .add-to-cart {
      &.not-in-cart {
        display: block;
        opacity: 1;
      }
    }
    .add-to-list {
      display: block;
      width: 35px;
      padding: 2px;
    }
  }

  &.disable {
    * {
      filter: blur(3px);
    }
  }

  &.role {
    &.cropped{
      border: solid 1px #292727;
    }
    &:hover {
      -moz-box-shadow: inset 0 0 10px #808080;
      -webkit-box-shadow: inset 0 0 10px #808080;
      box-shadow: inset 0 0 10px #808080;

      .card-img {
        height: 200px;
      }

      .add-to-cart {
        &.not-in-cart {
          display: none;
          opacity: 0;
        }
      }
    }
  }

  .item-card-details {
    position: relative;
    overflow: hidden;
    width: 100%; /* תופס את כל הרוחב של האזור */
  
  }
  .inventoryEnd-btn {
    background-color: black;
    border-radius: 50%;
    border-color: black;
    width: auto;
    height: auto;
    color: white;
    align-items: center;
    font-size: 12px;
    font-style: bol;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    margin: inherit;
    z-index: 1;
    left: 1%;
    top: 1%;
    position: absolute;
  }

  .inventoryEnd {
    border: 3px solid black;
    background-color: black;
    border-radius: 50%;
    color: white;
    width: 40px;
    height: 27px;
    font-style: bol;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    margin: inherit;
    z-index: 1;
    left: 10%;
    top: 10px;
    position: absolute;
    font-size: 14px;
    text-align: center;
  }

  .disable-product {
    top: 65px;
    font-size: 10px;
    align-items: center;
  }

  .inventory {
    border: 3px solid;
    background-color: white;
    border-radius: 50%;
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 5px;
    margin: inherit;
    z-index: 1;
    left: 1%;
    position: absolute;
    font-weight: 600;
    display: flex;
    font-size: 17px;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    top: 1%;
  }

  .inventoryQuantity {
    border-color: rgb(131, 248, 77);
  }

  .inventory.red {
    border-color: red;
  }

  .inventory.gray {
    border-color: black;
  }

  .card-img-in-card {
    overflow: hidden;
    height: 165px;
    position: relative;
  }

  .card-img {
    overflow: hidden;
    height: 200px;
    width: none;
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the image covers the entire div */
  }

  .sale-text {
    height: 45px;
    width: 50px;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-around;
    align-items: center;

    p {
      margin: 0px;
      text-align: center;
      padding: 0px;
      font-size: 14px;
      width: 75%;
    }
  }

  img {
    display: block;
    width: 100%;
    transform: none;
    transition: all 2s;
  }

  h4 {
    font-size: 13px;
    max-width: 95%;
    margin: 0px;
    font-weight: 500;
  }

  h5 {
    font-size: 12px;
    max-width: 95%;
    margin: 0px;
    font-weight: 300;
  }

  .desc {
    max-height: 85px;
    display: flex;
    flex-direction: column;
    .product-info {
      max-height: 70px;
    }
  }

  .prod-info-quantity {
    .weight-quantity {
      margin-left: 3px;
    }
  }

  .kosher-and-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .price-main-cont {
      .price {
        position: relative;
        color: #e3032e;
        font-size: 15px;
        font-weight: bold;
      
        &:before {
          content: "₪";
          padding-right: 2px;
        }

        &.with-sale::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: black;
          transform: rotate(-15deg);
          transform-origin: center;
        }
      }
      
      
      
    }
    .price-old {
      position: relative;
      color: #e3032e;
      font-size: 15px;
      font-weight: bold;

    }
    .prod-info-kosher {
      font-size: 8px;
      img {
        width: 40px;
      }
    }
  }

  .price {
    p {
      &:before {
        content: "\20AA";
      }

      &.empty-price {
        margin: 0;
        color: #a0a0a0;
        font-weight: 700;
        font-size: 18px;

        &:before {
          display: none;
        }
      }
    }

    .single-price {
      p {
        margin: 0;
        color: #09a6a7;
        font-weight: 700;
        font-size: 18px;
      }
    }

    .price-with-discount {
      p {
        display: inline-block;
        margin: 0;
        padding-left: 16px;
        font-size: 18px;

        &.o {
          color: #a0a0a0;
          text-decoration: line-through;
        }

        &.n {
          color: #09a6a7;
          font-weight: 700;
        }
      }
    }
  }
  .top-card-section {
    position: relative;
    width: 100%;



    .location {
    display: flex;
    flex-wrap: wrap;
  }

    .edit-prod-cls {
      position: absolute;
      top: 1%;
      right: 1%;
      z-index: 2;

      img {
        width: 25px;
      }
    }
  }

  
}

.add-to-cart {
  width: 60%;
  margin: auto;
  transition: width 0.5s;
  opacity: 0;

  &.not-in-cart {
    width: 65%;
    display: none;
    cursor: pointer;
  }

  &.in-cart {
    display: block;
    opacity: 1;
  }

  .wrap {
    background: $c1;
    display: flex;
    padding: 0px 8px;
    justify-content: space-evenly;
    border-radius: 20px;
    align-items: center;
    min-height: 30px;

    .add-btn,
    .minus-btn {
      width: 25%;

      p {
        font-size: 16px;
        font-weight: bold;
        color: white;
      }

      &:active {
        content: "";
        background: darken($c1, 3%);
      }
    }

    img {
      display: block;
      height: 15px;
      margin: 0 auto;
      cursor: pointer;
    }

    input {
      border: none;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      color: white;
      text-align: center;
      background-color: transparent;
    }

    p {
      color: #fff;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      margin: auto;
    }
  }

  .unit-cont {
    cursor: pointer;
    background: #fff;

    .col-lg-6 {
      background: #c98f0a30;

      p {
        text-align: center;
        margin: 10px 0;
        color: black;
      }

      &.active {
        background: $c1;

        p {
          color: #fff;
        }
      }
    }

    .col-lg-12 {
      background: $c1;

      p {
        text-align: center;
        margin: 10px 0;
        color: #fff;
      }
    }
  }
}

.add-row {
  margin-right: 20px;
  .btn {
    font-size: 16px;
    width: 10%;
    height: 30px;
    color: #e3032e;
    border: 1px solid #e3032e;
    padding: 1px;
    margin: 3px 3px;
    border-radius: 5px;
    background-color: #fff;
    }
    .btn:hover {
      background-color: #ffe6e9;
    }
    .quantity-input {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
    
    .quantity-input input {
      width: 50px;
      text-align: center;
      margin: 0 5px;
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    
    .quantity-input button {
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #f0f0f0;
      cursor: pointer;
    }
    
    .quantity-input button:hover {
      background-color: #e0e0e0;
    }
}


.product-location {
  display: flex;
  margin: 10px;
  flex-direction: column;
  align-items: flex-start;

  .product-header {
    margin-top: 10px;

    h2 {
      margin: 5px 10px;
      font-size: 20px;
    }
  }
  .table-location {
    height: 150px;
    overflow-y: auto;
    width: 100%;
  }

  table {
    max-width: 1200px;
    align-items: center;
    width: 95%;
    padding: 20px 8px;

    th {
      text-align: center;
      background-color: $c1;
      margin: 5px 0px;
    }

    td {
      text-align: center;
    }
  }

  
}

// עבור מנהל אפשרות להשוות מחירים
.product-wrapper-compare {
  align-items: flex-start;
  max-width: 1200px;
  padding: 25px 15px;
  justify-content: space-between;

  .information {
    display: flex;
    margin-bottom: 15px;
    line-height: 0px;
    align-items: center;

    .line {
      padding: 0px 5px;
      font-size: 24px;
    }

    .c-info {
      color: #002e45;
      font-size: 16px;
      padding-left: 2px;
    }
  }

  .image {
    position: relative;
    cursor: unset;
    padding: 5px;
    width: 240px;

    .img-comp {
      background-image: url(#{$get-path-to-assets}products/product.jpg);
      width: 240px;
      height: 240px;
      padding: 5px;
      background-size: 250px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      touch-action: pan-right pinch-zoom;
    }
  }
}

.extra-info-compare {
  margin: 0px 10px;
  display: flex;

  .prod-extra-info-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.compare-prices {
  width: 100%;
  padding: 0px 0px 10px 0px;

  .product-header {
    transform: translateY(-200px);
    position: absolute;
    width: 100%;
  }
  .table-compare {
    height: 150px;
    overflow-y: auto;
  }

  .data-company {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;

    p,
    h3 {
      font-weight: 500;
      margin: 2px 2px;
      padding: 2px 0px;
    }
  }

  table {
    max-width: 1200px;
    align-items: center;
    width: 96%;
    margin: 10px auto;
    padding: 20px 8px;
    border: 1px solid black;

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    th {
      text-align: center;
      background-color: $c1;
      margin: 5px 0px;
    }

    tr {
      padding: 5px 0px;
    }

    td {
      margin: 5px 0px;
    }
  }
}

.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;

  .compare-prices {
    width: 50%;
  }

  //עבור מנהל אפשרות לעדכן פרטי מוצר
  .edit-prod-cls {
    img {
      width: 30px;
    }
  }
}

.add-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .add-to-list {
    display: none;
  }
}